import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { auth, firestore } from "../firebase.js";
import { onAuthStateChanged } from "firebase/auth";
import { collection, getDocs } from "firebase/firestore";
import defaultDogImage from "../assets/landing-page-graphic-AI-Dog-Trainer.webp";

// Sample icons for skills
const skillIcons = {
  "Sit on a Surfboard": "🏄",
  "Put Toys Away": "🧺",
  "Shake Paw": "🤝",
  "Ring a Bell to Go Outside": "🛎️",
  "Spin in a Circle": "🪩",
  "Close the Door": "🚪",
};

// Value snapshot icons
const valueIcons = {
  "Smart AI-powered training": "🧠",
  "Real-time progress tracking": "📈",
  "Custom trick ideas": "💡",
  "Positive reinforcement techniques": "🎓",
  "Train through with AI on your side": "🤖",
  "Mobile & web access": "📱",
};

// Sample recommended skills
const recommendedSkills = [
  { name: "Sit & Stay", icon: "🧘" },
  { name: "Shake Paw", icon: "🤝" },
  { name: "Spin in a Circle", icon: "🪩" },
];

// Updated training journey timeline (months)
const trainingJourney = [
  { month: "Month 1", skill: "Basic Skills" },
  { month: "Month 2", skill: "Recall Games" },
  { month: "Month 3", skill: "Fun Tricks like High Five!" },
  { month: "Month 4", skill: "Impulse Control at the Door" },
];

const DogDashboard = () => {
  const navigate = useNavigate();
  const [dogData, setDogData] = useState(null);
  const [isFirstLogin, setIsFirstLogin] = useState(true);
  const [loading, setLoading] = useState(true);
  const [hoveredCta, setHoveredCta] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        try {
          const dogsCollectionRef = collection(firestore, `users/${currentUser.uid}/dogs`);
          const dogQuerySnapshot = await getDocs(dogsCollectionRef);
          if (!dogQuerySnapshot.empty) {
            const dogDoc = dogQuerySnapshot.docs[0];
            setDogData(dogDoc.data());
          } else {
            navigate("/onboarding");
          }
        } catch (error) {
          console.error("Error fetching dog data:", error);
          navigate("/onboarding");
        } finally {
          setLoading(false);
        }
      } else {
        navigate("/login");
      }
    });
    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    if (isFirstLogin && dogData) {
      setTimeout(() => setIsFirstLogin(false), 3000);
    }
  }, [dogData, isFirstLogin]);

  const getStyles = () => ({
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      minHeight: "100vh",
      padding: "20px",
      position: "relative",
      overflow: "hidden",
    },
    contentWrapper: {
      width: "100%",
      maxWidth: "1200px",
      padding: "0 10px",
      boxSizing: "border-box",
      zIndex: 1,
    },
    heroSection: {
      backgroundColor: "#fff",
      borderRadius: "16px",
      padding: "30px",
      textAlign: "center",
      boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
      marginBottom: "60px",
      width: "100%",
      position: "relative",
      overflow: "hidden",
    },
    welcomeText: {
      fontFamily: "MouseMemoirs, sans-serif",
      fontSize: window.innerWidth < 768 ? "28px" : "36px",
      fontWeight: "bold",
      color: "#2D3748",
      marginBottom: "15px",
      letterSpacing: "1px",
    },
    dogAvatar: {
      width: window.innerWidth < 768 ? "120px" : "150px",
      height: window.innerWidth < 768 ? "120px" : "150px",
      borderRadius: "50%",
      objectFit: "cover",
      border: "4px solid #38A169",
      animation: isFirstLogin ? "wag 1s ease-in-out infinite" : "none",
      boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
    },
    dogStats: {
      display: "flex",
      flexDirection: window.innerWidth < 768 ? "column" : "row",
      justifyContent: "center",
      gap: window.innerWidth < 768 ? "10px" : "20px",
      marginTop: "15px",
      fontFamily: "Poppins, sans-serif",
      fontSize: "16px",
      color: "#4A5568",
    },
    skillSection: {
      marginBottom: "60px",
      width: "100%",
    },
    skillHeader: {
      fontFamily: "MouseMemoirs, sans-serif",
      fontSize: window.innerWidth < 768 ? "28px" : "36px",
      fontWeight: "bold",
      color: "#2D3748",
      textAlign: "center",
      marginBottom: "15px",
    },
    skillSubheader: {
      fontFamily: "Poppins, sans-serif",
      fontSize: "16px",
      color: "#4A5568",
      textAlign: "center",
      marginBottom: "20px",
    },
    skillGrid: {
      display: "grid",
      gridTemplateColumns: window.innerWidth < 768 ? "1fr" : "repeat(3, 1fr)",
      gap: "20px",
    },
    skillCard: {
      backgroundColor: "#E6F0FA",
      borderRadius: "12px",
      padding: "25px",
      textAlign: "center",
      border: "1px solid #D3C7A0",
      transition: "transform 0.3s ease, box-shadow 0.3s ease",
      cursor: "pointer",
    },
    skillCardHover: {
      transform: "translateY(-5px)",
      boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
    },
    skillIcon: {
      fontSize: "40px",
      marginBottom: "10px",
    },
    skillName: {
      fontFamily: "Poppins, sans-serif",
      fontSize: "16px",
      fontWeight: "bold",
      color: "#2D3748",
      marginBottom: "10px",
    },
    valueSection: {
      marginBottom: "60px",
      width: "100%",
    },
    valueHeader: {
      fontFamily: "MouseMemoirs, sans-serif",
      fontSize: window.innerWidth < 768 ? "24px" : "28px",
      fontWeight: "bold",
      color: "#2D3748",
      textAlign: "center",
      marginBottom: "20px",
    },
    valueGrid: {
      display: "grid",
      gridTemplateColumns: window.innerWidth < 768 ? "1fr" : "repeat(3, 1fr)",
      gap: "20px",
    },
    valueCard: {
      backgroundColor: "#FFF8E8",
      borderRadius: "12px",
      padding: "20px",
      textAlign: "center",
      border: "1px solid #D3C7A0",
      transition: "transform 0.3s ease",
    },
    valueIcon: {
      fontSize: "40px",
      marginBottom: "10px",
      animation: "bounce 2s infinite",
    },
    valueText: {
      fontFamily: "Poppins, sans-serif",
      fontSize: "16px",
      color: "#4A5568",
    },
    aiSection: {
      marginBottom: "60px",
      width: "100%",
    },
    aiHeader: {
      fontFamily: "MouseMemoirs, sans-serif",
      fontSize: window.innerWidth < 768 ? "24px" : "28px",
      fontWeight: "bold",
      color: "#2D3748",
      textAlign: "center",
      marginBottom: "20px",
    },
    aiCard: {
      backgroundColor: "#fff",
      borderRadius: "12px",
      padding: "20px",
      textAlign: "center",
      boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
      border: "1px dashed #FF6F61", // Playful dashed border
    },
    aiIcon: {
      fontSize: "40px",
      marginBottom: "10px",
    },
    aiText: {
      fontFamily: "Poppins, sans-serif",
      fontSize: "16px",
      color: "#4A5568",
      marginBottom: "15px",
    },
    ctaSection: {
      textAlign: "center",
      marginBottom: "60px",
      width: "100%",
    },
    ctaButton: {
      backgroundColor: "#38A169",
      color: "#fff",
      border: "none",
      padding: window.innerWidth < 768 ? "14px 30px" : "15px 35px",
      borderRadius: "50px",
      fontFamily: "Poppins, sans-serif",
      fontSize: "18px",
      fontWeight: "bold",
      cursor: "pointer",
      transition: "transform 0.2s ease, background-color 0.3s ease",
      boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)",
    },
    ctaButtonHover: {
      backgroundColor: "#2F855A",
      transform: "scale(1.05)",
    },
    timelineSection: {
      marginBottom: "60px",
      width: "100%",
    },
    timelineHeader: {
      fontFamily: "MouseMemoirs, sans-serif",
      fontSize: window.innerWidth < 768 ? "24px" : "28px",
      fontWeight: "bold",
      color: "#2D3748",
      textAlign: "center",
      marginBottom: "20px",
    },
    timeline: {
      display: "flex",
      flexDirection: window.innerWidth < 768 ? "column" : "row",
      justifyContent: "center",
      gap: "20px",
    },
    timelineItem: {
      backgroundColor: "#fff",
      borderRadius: "8px",
      padding: "15px",
      textAlign: "center",
      boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
      width: window.innerWidth < 768 ? "100%" : "200px",
      borderLeft: "4px solid #FF6F61", // Playful coral accent
    },
    didYouKnowSection: {
      backgroundColor: "#FFF8E8",
      borderRadius: "12px",
      padding: "20px",
      textAlign: "center",
      boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
      marginBottom: "60px",
      width: "100%",
      position: "relative",
    },
    didYouKnowHeader: {
      fontFamily: "MouseMemoirs, sans-serif",
      fontSize: "20px",
      fontWeight: "bold",
      color: "#2D3748",
      marginBottom: "10px",
    },
    didYouKnowText: {
      fontFamily: "Poppins, sans-serif",
      fontSize: "16px",
      color: "#4A5568",
    },
    pawPrint: {
      position: "absolute",
      fontSize: "24px",
      color: "#FF6F61",
      opacity: 0.2,
    },
  });

  const styles = getStyles();
  const [hoveredSkill, setHoveredSkill] = useState(null);

  if (loading) return <div style={{ textAlign: "center", padding: "50px", fontFamily: "Poppins, sans-serif" }}>Loading...</div>;
  if (!dogData) return null;

  return (
    <div style={styles.container}>
      <div style={styles.contentWrapper}>
        {/* Hero Section */}
        <motion.div
          style={styles.heroSection}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <h1 style={styles.welcomeText}>
            {dogData.dogName} is ready to learn!
          </h1>
          <motion.img
            src={dogData.dogImage || defaultDogImage}
            alt={`${dogData.dogName}`}
            style={styles.dogAvatar}
            initial={{ scale: 0.9 }}
            animate={{ scale: 1 }}
            transition={{ duration: 0.5 }}
          />
          <div style={styles.dogStats}>
            <span>Breed: {dogData.dogBreed}</span>
            <span>Age: {dogData.dogAge}</span>
            <span>Level: Beginner</span>
          </div>
          <span style={{ ...styles.pawPrint, top: "10px", left: "10px" }}>🐾</span>
          <span style={{ ...styles.pawPrint, bottom: "10px", right: "10px" }}>🐾</span>
        </motion.div>

        <motion.div
          style={styles.ctaSection}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <button
            style={{
              ...styles.ctaButton,
              ...(hoveredCta && styles.ctaButtonHover),
            }}
            onMouseEnter={() => setHoveredCta(true)}
            onMouseLeave={() => setHoveredCta(false)}
            onClick={() => navigate("/skills")}
            aria-label={`View personalized training plan for ${dogData.dogName}`}
          >
            View Personalized Training Plan
          </button>
        </motion.div>

        {/* Skill Potential Highlight */}
        <motion.div
          style={styles.skillSection}
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
        >
          <h2 style={styles.skillHeader}>
            Teach {dogData.dogName} Almost Anything
          </h2>
          <p style={styles.skillSubheader}>
            With the power of AI, unlock an endless library of personalized dog training.
          </p>
          <div style={styles.skillGrid}>
            {Object.keys(skillIcons).map((skill, index) => (
              <motion.div
                key={index}
                style={{
                  ...styles.skillCard,
                  ...(hoveredSkill === index && styles.skillCardHover),
                }}
                onMouseEnter={() => setHoveredSkill(index)}
                onMouseLeave={() => setHoveredSkill(null)}
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.3 }}
              >
                <div style={styles.skillIcon}>{skillIcons[skill]}</div>
                <div style={styles.skillName}>{skill}</div>
              </motion.div>
            ))}
          </div>
        </motion.div>

        {/* Value Snapshot Section */}
        <motion.div
          style={styles.valueSection}
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
        >
          <h2 style={styles.valueHeader}>What You Get</h2>
          <div style={styles.valueGrid}>
            {Object.keys(valueIcons).map((value, index) => (
              <motion.div
                key={index}
                style={styles.valueCard}
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.3 }}
              >
                <div style={styles.valueIcon}>{valueIcons[value]}</div>
                <p style={styles.valueText}>{value}</p>
              </motion.div>
            ))}
          </div>
        </motion.div>

        {/* AI Smartest Dog Trainer Section */}
        <motion.div
          style={styles.aiSection}
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
        >
          <h2 style={styles.aiHeader}>The Smartest Dog Trainer at Your Fingertips</h2>
          <div style={styles.aiCard}>
            <motion.div style={styles.aiIcon} whileHover={{ rotate: 10 }} transition={{ duration: 0.3 }}>🤖</motion.div>
            <p style={styles.aiText}>
              Harness the power of AI to guide you and {dogData.dogName} through personalized training plans, anytime, anywhere.
            </p>
          </div>
        </motion.div>

        {/* Training Journey Timeline */}
        <motion.div
          style={styles.timelineSection}
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
        >
          <h2 style={styles.timelineHeader}>Your Training Journey</h2>
          <div style={styles.timeline}>
            {trainingJourney.map((item, index) => (
              <motion.div
                key={index}
                style={styles.timelineItem}
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <p style={{ fontWeight: "bold", color: "#FF6F61" }}>{item.month}</p>
                <p>{item.skill}</p>
              </motion.div>
            ))}
          </div>
        </motion.div>

        {/* Did You Know? */}
        <motion.div
          style={styles.didYouKnowSection}
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
        >
          <h2 style={styles.didYouKnowHeader}>Did You Know?</h2>
          <p style={styles.didYouKnowText}>
            {dogData.dogBreed === "Labrador Retriever"
              ? "Labrador Retrievers are paw-some at learning tricks—perfect for a wag-tastic adventure!"
              : `The ${dogData.dogBreed} loves a good challenge and thrives with treats and praise!`}
          </p>
          <span style={{ ...styles.pawPrint, top: "5px", left: "5px" }}>🐾</span>
          <span style={{ ...styles.pawPrint, bottom: "5px", right: "5px" }}>🐾</span>
        </motion.div>

        {/* Final CTA Button */}
        <motion.div
          style={styles.ctaSection}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <button
            style={{
              ...styles.ctaButton,
              ...(hoveredCta && styles.ctaButtonHover),
            }}
            onMouseEnter={() => setHoveredCta(true)}
            onMouseLeave={() => setHoveredCta(false)}
            onClick={() => navigate("/skills")}
            aria-label={`View personalized training plan for ${dogData.dogName}`}
          >
            View Personalized Training Plan
          </button>
        </motion.div>
      </div>

      <style>
        {`
          @keyframes wag {
            0% { transform: rotate(0deg); }
            25% { transform: rotate(5deg); }
            75% { transform: rotate(-5deg); }
            100% { transform: rotate(0deg); }
          }
          @keyframes bounce {
            0%, 100% { transform: translateY(0); }
            50% { transform: translateY(-5px); }
          }
        `}
      </style>
    </div>
  );
};

export default DogDashboard;