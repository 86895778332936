import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { motion } from "framer-motion";
import { doc, getDoc, collection, getDocs, query, orderBy, setDoc } from "firebase/firestore";
import { firestore, auth } from "../firebase.js";
import { chat } from "../api/grok.js";
import { onAuthStateChanged } from "firebase/auth";
import { ArrowUpward } from "@mui/icons-material";
import Logo from "../assets/DogTrainerAI.png";
import "../styles.css";

const TrainerScreen = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const categoryId = searchParams.get("category");
  const skillId = searchParams.get("skill_id");
  const skillName = searchParams.get("skill_name");

  const [skill, setSkill] = useState(null);
  const [step, setStep] = useState(0);
  const [aiChatActive, setAiChatActive] = useState(false);
  const [chatHistory, setChatHistory] = useState([]);
  const [userInput, setUserInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [loadingDots, setLoadingDots] = useState("");
  const [userData, setUserData] = useState(null);
  const [dogData, setDogData] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [isLogoAnimated, setIsLogoAnimated] = useState(false);
  const [hoverStates, setHoverStates] = useState({
    clicker: false,
    help: false,
    previous: false,
    next: false,
    complete: false,
    back: false,
    dialog: false,
    close: false,
  });
  const [showIntroDialog, setShowIntroDialog] = useState(false);
  const chatBoxRef = useRef(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        const introKey = `hasSeenTrainerIntro_${user.uid}`;
        const hasSeenIntro = localStorage.getItem(introKey);
        if (!hasSeenIntro) {
          setShowIntroDialog(true);
        }
      }
    });
    return () => unsubscribe();
  }, []);

  const handleDismissDialog = () => {
    const user = auth.currentUser;
    if (user) {
      const introKey = `hasSeenTrainerIntro_${user.uid}`;
      localStorage.setItem(introKey, "true");
    }
    setShowIntroDialog(false);
  };

  useEffect(() => {
    const fetchSkillAndUserData = async (user) => {
      if (!categoryId || !skillId) {
        setError("Oops! We need a category and skill ID to get started.");
        return;
      }

      setLoading(true);
      try {
        const skillDocRef = doc(firestore, `skills/${categoryId}/skills`, skillId);
        const skillDoc = await getDoc(skillDocRef);
        if (skillDoc.exists()) {
          const skillData = skillDoc.data();
          let steps = [];
          if (skillData.steps && Array.isArray(skillData.steps)) {
            steps = skillData.steps;
          } else {
            const stepsQuery = query(
              collection(firestore, `skills/${categoryId}/skills/${skillId}/steps`),
              orderBy("order")
            );
            const stepsSnapshot = await getDocs(stepsQuery);
            steps = stepsSnapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
          }
          setSkill({
            name: skillData.name || skillName,
            steps: steps.map((step) => ({
              instruction: step.instruction || "No instruction provided",
              image_url: step.image_url || "https://placehold.co/600x300?text=No+Image",
            })),
          });
        } else {
          setError("Woof! That skill couldn’t be found.");
        }

        const userDocRef = doc(firestore, "users", user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          setUserData(userDoc.data());
        } else {
          await setDoc(userDocRef, { email: user.email }, { merge: true });
          setUserData({ email: user.email });
        }

        const dogsRef = collection(firestore, "users", user.uid, "dogs");
        const dogsSnapshot = await getDocs(dogsRef);
        if (!dogsSnapshot.empty) {
          const firstDog = dogsSnapshot.docs[0].data();
          setDogData(firstDog);
        } else {
          setError("No pup found—let’s add one in your profile!");
        }
      } catch (err) {
        setError("Oops! Something went wrong: " + err.message);
      } finally {
        setLoading(false);
        setDataLoaded(true);
      }
    };

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchSkillAndUserData(user);
      } else {
        navigate("/");
      }
    });

    return () => unsubscribe();
  }, [categoryId, skillId, skillName, navigate]);

  useEffect(() => {
    if (aiChatActive) {
      setIsLogoAnimated(true);
    }
  }, [aiChatActive]);

  useEffect(() => {
    if (loading) {
      const interval = setInterval(() => {
        setLoadingDots((prev) => (prev.length < 3 ? prev + "." : ""));
      }, 300);
      return () => clearInterval(interval);
    } else {
      setLoadingDots("");
    }
  }, [loading]);

  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [chatHistory]);

  const progress = skill ? (step >= skill.steps.length ? 100 : (step / skill.steps.length) * 100) : 0;

  const playSound = () => {
    const audio = new Audio("/assets/yes.mp3");
    audio.play().catch((err) => console.error("Audio error:", err.message));
  };

  const getSystemMessage = () => {
    return `
      You are a friendly, professional AI dog trainer, here to help teach ${userData?.firstName || "the user"} how to train ${dogData?.dogName || "their pup"} with love and fun!
      Keep answers short (3-5 sentences), direct, and personalized using:
      - User: ${userData?.firstName || "Unknown"}, Email: ${userData?.email || "N/A"}
      - Dog: Name: ${dogData?.dogName || "Unknown"}, Breed: ${dogData?.dogBreed || "Unknown"}, Age: ${dogData?.dogAge || "Unknown"}, Gender: ${dogData?.dogGender || "Unknown"}
      - Current Skill: ${skill?.name || "Unknown"}
      - Current Step: Step ${step + 1} - "${skill?.steps[step]?.instruction || "No instruction available"}"
    `;
  };

  const sendMessage = async () => {
    if (!userInput.trim() || loading || !dataLoaded) return;
    const newChat = [...chatHistory, { role: "user", content: userInput }];
    setChatHistory(newChat);
    setLoading(true);
    setError(null);
    try {
      const systemMessage = getSystemMessage();
      const response = await chat(
        [{ role: "system", content: systemMessage }, ...newChat],
        { temperature: 0.3, max_tokens: 100, model: "grok-2-1212" }
      );
      const assistantMessage = response?.data?.choices?.[0]?.message?.content || `Try a firm command and a treat, ${userData?.firstName || "friend"}! Be patient with ${dogData?.dogName || "your pup"}.`;
      setChatHistory([...newChat, { role: "assistant", content: assistantMessage }]);
    } catch (err) {
      setError(`Woof! Chat hiccup: ${err.message}`);
      setChatHistory([...newChat, { role: "assistant", content: `Try a firm command and a treat, ${userData?.firstName || "friend"}! Be patient with ${dogData?.dogName || "your pup"}.` }]);
    }
    setLoading(false);
    setUserInput("");
  };

  const handleSendPress = () => sendMessage();

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      sendMessage();
    }
  };

  const handleHover = (buttonName, isHovering) => {
    setHoverStates((prev) => ({ ...prev, [buttonName]: isHovering }));
  };

  if (loading && !aiChatActive) {
    return <p className="trainer-loading-text">Fetching {dogData?.dogName || "pup"}’s skill{loadingDots}</p>;
  }

  if (error && !aiChatActive) {
    return <p className="trainer-error-text">{error}</p>;
  }

  return (
    <div className="trainer-container">
      {/* Intro Dialog */}
      {showIntroDialog && (
        <motion.div
          className="trainer-dialog-overlay"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <motion.div
            className="trainer-dialog-box"
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <h2 className="trainer-dialog-heading">Woof-tastic Training Tips!</h2>
            <div className="trainer-bullet-container">
              <span className="trainer-bullet-icon">🐾</span>
              <span className="trainer-dialog-text">Flip through steps with <em>Next</em> & <em>Previous</em>.</span>
            </div>
            <div className="trainer-bullet-container">
              <span className="trainer-bullet-icon">🦴</span>
              <span className="trainer-dialog-text">Reward with treats & tap <em>Clicker</em> for fun!</span>
            </div>
            <div className="trainer-bullet-container">
              <span className="trainer-bullet-icon">🤖</span>
              <span className="trainer-dialog-text">Need help? Ask the AI with <em>Help</em>.</span>
            </div>
            <motion.button
              className="trainer-dialog-button"
              onClick={handleDismissDialog}
              onMouseEnter={() => handleHover("dialog", true)}
              onMouseLeave={() => handleHover("dialog", false)}
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.2 }}
            >
              Let’s Train!
            </motion.button>
            <span className="paw-print top-left">🐾</span>
            <span className="paw-print bottom-right">🐾</span>
          </motion.div>
        </motion.div>
      )}

      <div className="trainer-content-wrapper">
        {aiChatActive ? (
          <motion.div
            className="trainer-chat-container"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <motion.img
              src={Logo}
              className="trainer-chat-logo"
              alt="Doglingo AI Logo"
              initial={{ scale: 0.8 }}
              animate={{ scale: 1 }}
              transition={{ duration: 0.5 }}
            />
            <h2 className="trainer-heading">Doglingo AI</h2>
            <p className="trainer-text">{`Hey ${userData?.firstName || "friend"}! How’s ${dogData?.dogName || "your pup"} doing?`}</p>
            <div className="trainer-chat-box" ref={chatBoxRef}>
              {chatHistory.length === 0 && (
                <p className="trainer-text" style={{ color: "#777", fontStyle: "italic" }}>
                  Woof! Ask me anything about training {dogData?.dogName || "your pup"}!
                </p>
              )}
              {chatHistory.map((chat, index) => (
                <motion.div
                  key={index}
                  className={chat.role === "user" ? "trainer-user-message" : "trainer-ai-message"}
                  initial={{ opacity: 0, x: chat.role === "user" ? 20 : -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.3 }}
                >
                  <p>{chat.content || "Empty message"}</p>
                </motion.div>
              ))}
            </div>
            {error && <p className="trainer-error-text">{error}</p>}
            <div className="trainer-input-container">
              <textarea
                className="trainer-text-input"
                value={userInput}
                onChange={(e) => setUserInput(e.target.value)}
                placeholder={`Ask about ${dogData?.dogName || "your pup"}...`}
                onKeyPress={handleKeyPress}
              />
              <motion.button
                className="trainer-send-button"
                onClick={handleSendPress}
                disabled={loading || !dataLoaded}
                whileHover={{ scale: 1.1 }}
                transition={{ duration: 0.2 }}
              >
                {loading ? loadingDots : <ArrowUpward />}
              </motion.button>
            </div>
            <motion.button
              className="trainer-back-button"
              onClick={() => setAiChatActive(false)}
              onMouseEnter={() => handleHover("back", true)}
              onMouseLeave={() => handleHover("back", false)}
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.2 }}
            >
              Back
            </motion.button>
          </motion.div>
        ) : (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8 }}
          >
            <div className="trainer-progress-container">
              <motion.button
                className="trainer-close-button"
                onClick={() => navigate("/skills")}
                onMouseEnter={() => handleHover("close", true)}
                onMouseLeave={() => handleHover("close", false)}
                whileHover={{ scale: 1.1 }}
                transition={{ duration: 0.2 }}
              >
                X
              </motion.button>
              <div className="trainer-progress-bar">
                <motion.div
                  className="trainer-progress-fill"
                  initial={{ width: 0 }}
                  animate={{ width: `${progress}%` }}
                  transition={{ duration: 0.5 }}
                />
              </div>
              <span className="trainer-progress-text">
                Step {step + 1} of {skill?.steps.length || 0}
              </span>
            </div>
            <h2 className="trainer-heading">
              🐶 {skill?.name} - Step {step + 1}
            </h2>
            <p className="trainer-text">
              {skill?.steps[step]?.instruction || "No instruction available"}
            </p>
            <motion.img
              src={skill?.steps[step]?.image_url || "https://placehold.co/600x300?text=No+Image"}
              className="trainer-image"
              alt={`Step ${step + 1} illustration`}
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
            />
            <div className="trainer-action-button-container">
              <motion.button
                className="trainer-button"
                onClick={playSound}
                onMouseEnter={() => handleHover("clicker", true)}
                onMouseLeave={() => handleHover("clicker", false)}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                transition={{ duration: 0.2 }}
              >
                🦴 Clicker
              </motion.button>
              <motion.button
                className="trainer-button"
                onClick={() => setAiChatActive(true)}
                onMouseEnter={() => handleHover("help", true)}
                onMouseLeave={() => handleHover("help", false)}
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.2 }}
              >
                🤖 Ask AI
              </motion.button>
            </div>
            <div className="trainer-nav-container">
              {step > 0 && (
                <motion.button
                  className="trainer-button"
                  onClick={() => setStep(step - 1)}
                  onMouseEnter={() => handleHover("previous", true)}
                  onMouseLeave={() => handleHover("previous", false)}
                  whileHover={{ scale: 1.05 }}
                  transition={{ duration: 0.2 }}
                >
                  ⬅️ Previous
                </motion.button>
              )}
              {step < skill?.steps.length - 1 ? (
                <motion.button
                  className="trainer-button"
                  onClick={() => setStep(step + 1)}
                  onMouseEnter={() => handleHover("next", true)}
                  onMouseLeave={() => handleHover("next", false)}
                  whileHover={{ scale: 1.05 }}
                  transition={{ duration: 0.2 }}
                >
                  Next ➡️
                </motion.button>
              ) : (
                <motion.button
                  className="trainer-button"
                  onClick={() =>
                    navigate(
                      `/rate?category=${categoryId}&skill_id=${skillId}&skill_name=${encodeURIComponent(skillName)}`
                    )
                  }
                  onMouseEnter={() => handleHover("complete", true)}
                  onMouseLeave={() => handleHover("complete", false)}
                  whileHover={{ scale: 1.05 }}
                  transition={{ duration: 0.2 }}
                >
                  ⭐ Complete
                </motion.button>
              )}
            </div>
          </motion.div>
        )}
      </div>
    </div>
  );
};

export default TrainerScreen;