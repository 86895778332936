import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth, firestore } from "../firebase.js";
import { onAuthStateChanged } from "firebase/auth";
import { doc, getDoc, setDoc, collection, getDocs } from "firebase/firestore";
import { useDropzone } from "react-dropzone";
import "../styles.css";

const EditProfileScreen = () => {
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState(null);
  const [userData, setUserData] = useState({ email: "" });
  const [dogData, setDogData] = useState({
    dogName: "",
    dogGender: "",
    dogAge: "",
    dogBreed: "",
    skills: [],
    pottyTrained: "",
    behavioralIssues: [],
    dogImage: "",
  });
  const [dogId, setDogId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [uploading, setUploading] = useState(false);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        const imageUrl = URL.createObjectURL(file);
        setDogData((prev) => ({ ...prev, dogImage: imageUrl, dogImageFile: file }));
      }
    },
  });

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (!user) {
        navigate("/login");
      } else {
        setCurrentUser(user);
        try {
          const userDocRef = doc(firestore, "users", user.uid);
          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists()) {
            setUserData(userDoc.data());
          }

          const dogsRef = collection(firestore, "users", user.uid, "dogs");
          const dogsSnapshot = await getDocs(dogsRef);
          if (!dogsSnapshot.empty) {
            const firstDogDoc = dogsSnapshot.docs[0];
            setDogId(firstDogDoc.id);
            setDogData(firstDogDoc.data());
          }
        } catch (err) {
          setError("Failed to load profile: " + err.message);
        } finally {
          setLoading(false);
        }
      }
    });
    return () => unsubscribe();
  }, [navigate]);

  const handleUserChange = (field, value) => {
    setUserData((prev) => ({ ...prev, [field]: value }));
  };

  const handleDogChange = (field, value) => {
    setDogData((prev) => ({ ...prev, [field]: value }));
  };

  const handleSave = async () => {
    if (!currentUser) return;
    setLoading(true);
    setError("");
    setSuccess("");

    try {
      let updatedDogImage = dogData.dogImage;
      if (dogData.dogImageFile) {
        setUploading(true);
        console.log("Converting image to Base64...");
        updatedDogImage = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.readAsDataURL(dogData.dogImageFile);
        });
        console.log("Image converted to Base64:", updatedDogImage.substring(0, 50) + "...");
        setUploading(false);
      }

      const userDocRef = doc(firestore, "users", currentUser.uid);
      await setDoc(userDocRef, userData, { merge: true });
      console.log("User data saved to Firestore at:", userDocRef.path);

      if (dogId) {
        const dogDocRef = doc(firestore, "users", currentUser.uid, "dogs", dogId);
        const { dogImageFile, ...dogDataToSave } = dogData;
        const updatedDogData = {
          ...dogDataToSave,
          dogImage: updatedDogImage,
        };
        console.log("Attempting to save dog data to Firestore:", updatedDogData);
        await setDoc(dogDocRef, updatedDogData, { merge: true });
        console.log("Dog data saved to Firestore at:", dogDocRef.path);
      }

      setSuccess("Profile updated successfully!");
      navigate("/skills");
    } catch (err) {
      console.error("Save error:", { code: err.code, message: err.message, stack: err.stack });
      setError("Failed to save profile: " + err.message);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <p className="edit-profile-loading-text">Loading...</p>;

  return (
    <div className="edit-profile-container">
      <div className="edit-profile-content-wrapper">
        <div className="edit-profile-section">
          <h2 className="edit-profile-title">Your Info</h2>
          <input
            className="edit-profile-input"
            value={userData.email || ""}
            onChange={(e) => handleUserChange("email", e.target.value)}
            placeholder="Email"
          />
        </div>

        <div className="edit-profile-section">
          <h2 className="edit-profile-title">Your Dog’s Info</h2>
          {dogData.dogImage && (
            <img src={dogData.dogImage} className="edit-profile-image-preview" alt="Dog Profile" />
          )}
          <div {...getRootProps()} className="edit-profile-image-picker">
            <input {...getInputProps()} />
            <span className="edit-profile-image-picker-text">
              {uploading ? "Uploading..." : "📸 Update Dog Picture (Drag or Click)"}
            </span>
          </div>
          <input
            className="edit-profile-input"
            value={dogData.dogName || ""}
            onChange={(e) => handleDogChange("dogName", e.target.value)}
            placeholder="Dog’s Name"
          />
          <select
            className="edit-profile-select"
            value={dogData.dogGender || ""}
            onChange={(e) => handleDogChange("dogGender", e.target.value)}
          >
            <option value="">Select Gender</option>
            <option value="Girl">Girl</option>
            <option value="Boy">Boy</option>
          </select>
          <select
            className="edit-profile-select"
            value={dogData.dogAge || ""}
            onChange={(e) => handleDogChange("dogAge", e.target.value)}
          >
            <option value="">Select Age</option>
            <option value="Puppy (Less than a year old)">Puppy (Less than a year old)</option>
            <option value="Young (1 - 3 Years)">Young (1 - 3 Years)</option>
            <option value="Adult (3 - 7 Years)">Adult (3 - 7 Years)</option>
            <option value="Senior (7+ Years)">Senior (7+ Years)</option>
          </select>
          <input
            className="edit-profile-input"
            value={dogData.dogBreed || ""}
            onChange={(e) => handleDogChange("dogBreed", e.target.value)}
            placeholder="Dog Breed"
          />
          <div className="edit-profile-multi-select-container">
            {["Sit", "Stay", "Come", "Heel", "Shake", "Down", "Leave it", "None"].map((skill) => (
              <div key={skill} className="edit-profile-multi-option">
                <label>
                  <input
                    type="checkbox"
                    className="edit-profile-checkbox"
                    checked={dogData.skills?.includes(skill) || false}
                    onChange={(e) => {
                      const updatedSkills = e.target.checked
                        ? [...(dogData.skills || []), skill]
                        : (dogData.skills || []).filter((s) => s !== skill);
                      handleDogChange("skills", updatedSkills);
                    }}
                  />
                  {skill}
                </label>
              </div>
            ))}
          </div>
          <select
            className="edit-profile-select"
            value={dogData.pottyTrained || ""}
            onChange={(e) => handleDogChange("pottyTrained", e.target.value)}
          >
            <option value="">Potty Trained?</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
          <div className="edit-profile-multi-select-container">
            {["Aggression", "Separation Anxiety", "Excessive Barking", "Jumping", "Chewing", "None"].map((issue) => (
              <div key={issue} className="edit-profile-multi-option">
                <label>
                  <input
                    type="checkbox"
                    className="edit-profile-checkbox"
                    checked={dogData.behavioralIssues?.includes(issue) || false}
                    onChange={(e) => {
                      const updatedIssues = e.target.checked
                        ? [...(dogData.behavioralIssues || []), issue]
                        : (dogData.behavioralIssues || []).filter((i) => i !== issue);
                      handleDogChange("behavioralIssues", updatedIssues);
                    }}
                  />
                  {issue}
                </label>
              </div>
            ))}
          </div>
        </div>

        {error && <p className="edit-profile-error-text">{error}</p>}
        {success && <p className="edit-profile-success-text">{success}</p>}
        <button
          className={`edit-profile-button ${loading || uploading ? "disabled" : ""}`}
          onClick={handleSave}
          disabled={loading || uploading}
        >
          {loading || uploading ? "Saving..." : "Save Changes"}
        </button>
      </div>
    </div>
  );
};

export default EditProfileScreen;